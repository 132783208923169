import { BehaviorSubject } from "rxjs";
import { Store } from "./store";

export type ErrorNotification = {
  message: string;
  type: "error" | "warn" | "info" | "success";
  code: number;
  isShown: boolean;
  duration?: number;
};

export class ErrorNotificationStore extends Store<ErrorNotification> {
  protected _observable: BehaviorSubject<ErrorNotification | null>;
  private static instance?: ErrorNotificationStore;
  static getInstance(): ErrorNotificationStore {
    ErrorNotificationStore.instance =
      ErrorNotificationStore.instance ?? new ErrorNotificationStore();
    return ErrorNotificationStore.instance;
  }
  private constructor() {
    super();
    this._observable = new BehaviorSubject<ErrorNotification | null>(null);
  }
  notifyByStatus(code: number, message?: string): void {
    if (code >= 200 && code < 300) {
      return this.add({
        code,
        isShown: false,
        message: message ?? "Sorry we could not find it",
        type: "warn",
        duration: 4000
      });
    }
    if (code === 404) {
      return this.add({
        isShown: false,
        message: message ?? "Sorry we could not find it",
        code,
        type: "warn",
        duration: 4000
      });
    }
    if (code >= 500) {
      return this.add({
        isShown: true,
        code,
        type: "warn",
        duration: 6000,
        message: message ?? "It seems to be problems with the server"
      });
    }
    if (Number.isInteger(code) && code > 0) {
      return this.add({
        isShown: true,
        code,
        type: "error",
        duration: 6000,
        message: message ?? "It seems to be problems with the connection"
      });
    }
  }
}
