import { RouteAppComponentProps } from "@/router/routes";
import { setPageTitle } from "@/utils/hook";
import { withTransaction } from "@elastic/apm-rum-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppPasswordRecoveryView, PasswordRecoveryStep } from "./view";

function _AppPasswordRecovery(props: RouteAppComponentProps) {
  const [params] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<PasswordRecoveryStep>(
    PasswordRecoveryStep.emailInput
  );
  useEffect(() => {
    if (
      params.get("user_id") &&
      params.get("signature") &&
      params.get("timestamp")
    ) {
      setCurrentStep(PasswordRecoveryStep.newPassword);
    }
  }, [params.get("user_id"), params.get("signature"), params.get("timestamp")]);
  useEffect(() => {
    setPageTitle(window)(props.pageName)();
  }, []);
  return (
    <AppPasswordRecoveryView
      currentStep={currentStep}
      onNextStepClicked={() =>
        setCurrentStep((prev) => {
          switch (prev) {
            case PasswordRecoveryStep.emailInput:
              return PasswordRecoveryStep.sendSuccess;
            case PasswordRecoveryStep.sendSuccess:
              return PasswordRecoveryStep.newPassword;
            default:
              return PasswordRecoveryStep.emailInput;
          }
        })
      }
      onPrevStepClicked={() => setCurrentStep(PasswordRecoveryStep.emailInput)}
    />
  );
}

const AppPasswordRecovery = withTransaction(
  "AppPasswordRecovery",
  "component"
)(_AppPasswordRecovery);

export default AppPasswordRecovery;
