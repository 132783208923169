import { BehaviorSubject } from "rxjs";
import { Store } from "./store";

export class AppLoadingStore extends Store<boolean> {
  protected _observable: BehaviorSubject<boolean | null>;
  private static instance?: AppLoadingStore;
  private constructor() {
    super();
    this._observable = new BehaviorSubject<boolean | null>(false);
  }
  static getInstance() {
    AppLoadingStore.instance =
      AppLoadingStore.instance ?? new AppLoadingStore();
    return AppLoadingStore.instance;
  }
}
