export class RequestError extends Error {
  status: number;
  constructor(
    status: number,
    message:
      | string
      | Record<string, string | string[] | Record<string, string[]>>
  ) {
    super();
    this.status = status;
    this.message =
      typeof message === "string" ? message : this.objToString(message);
  }
  objToString(
    obj: Record<string, string | string[] | Record<string, string[]>>
  ): string {
    return Object.entries(obj).reduce((acc, keyValue) => {
      return `${acc ? `${acc},` : acc} ${keyValue[0].toUpperCase()} ${
        Array.isArray(keyValue[1])
          ? keyValue[1].join(",")
          : typeof keyValue[1] === "string"
            ? keyValue[1]
            : this.objToString(keyValue[1])
      }`;
    }, "");
  }
}
