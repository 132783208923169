import { Grid, TextField, TextFieldProps, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { forwardRef } from "react";

export type AppTextFieldProps = Omit<TextFieldProps, "size" | "variant"> & {
  size?: "sm" | "lg";
  variant?: "outlined" | "standard";
};

const AppTextField = withStyles(() => ({
  root: {
    backgroundColor: "var(--app-input-bg-color)",
    borderRadius: "4px",
    width: "100%",
    "& label.Mui-focused": {
      color: "var(--app-font-light)"
    },
    "& .MuiOutlinedInput-root": {
      borderWidth: "0px",
      color: "var(--app-font-light)",
      "& fieldset": {
        borderWidth: "0px",
        minHeight: "36px"
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 16px",
      minHeight: "24px",
      lineHeight: "36px",
      fontSize: "14px",
      borderRadius: "4px"
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)"
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "4px 16px"
    },
    "& .MuiInputBase-multiline": {
      padding: "4px 8px",
      "& .MuiInputBase-inputMultiline": {
        padding: "0px"
      }
    }
  }
}))(
  forwardRef(
    (
      { size = "lg", variant = "standard", ...props }: AppTextFieldProps,
      ref?: any
    ) => (
      <Grid container direction="column" rowGap={1}>
        <TextField
          ref={ref}
          {...props}
          error={false}
          helperText={null}
          style={{
            ...props.style,
            height: props.multiline ? "100%" : size === "lg" ? "40px" : "36px",
            paddingTop: size === "lg" ? "4px" : "0px",
            border:
              variant === "standard"
                ? "none"
                : "1px solid var(--app-input-border-color) !important"
          }}
        />
        {props.error && props.helperText && (
          <Typography variant="caption" color="red">
            {props.helperText}
          </Typography>
        )}
      </Grid>
    )
    // )
  )
);

export default AppTextField;
