import { unstable_createMuiStrictModeTheme } from "@mui/material/styles";

export const mainTheme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      main: "#101c2a"
    },
    secondary: {
      main: "#fafafa"
    },
    text: {
      primary: "#101c2a",
      secondary: "#fff"
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontSize: "2.25rem",
      fontWeight: 500,
      color: "#666"
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
      color: "#666"
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
      letterSpacing: "-1px",
      color: "#666"
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
      color: "#666"
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
      color: "#666"
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
      color: "#666"
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 700
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 300,
      color: "#666"
    },
    body1: {
      fontSize: "1rem",
      color: "#666"
    },
    body2: {
      fontSize: "0.875rem",
      color: "#666",
      fontWeight: 300
    },
    button: {
      fontSize: "0.875rem",
      color: "#666",
      fontWeight: 400,
      textTransform: "none"
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#0275d8"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row"
        }
      }
    }
  }
});
