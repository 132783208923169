import { lazy } from "react";

export const Home = lazy(() => import("@/pages/AppHome"));

export const AppSignUp = lazy(() => import("@/pages/AppSignUp"));
export const AppSignUpCodeConfirmation = lazy(
  () => import("@/pages/AppSignUpConfirmation")
);
export const AppSignUpCheckbox = lazy(
  () => import("@/pages/AppSignUpCheckbox")
);
export const AppSignUpProject = lazy(() => import("@/pages/AppSignUpProject"));

export const AppAuditManagement = lazy(
  () => import("@/pages/AppAuditManagement")
);
export const AppAuditManagementList = lazy(
  () => import("@/pages/AppAuditManagementList")
);
export const AppAuditManagementExport = lazy(
  () => import("@/pages/AppAuditManagementExport")
);
export const AppAuditManagementDetail = lazy(
  () => import("@/pages/AppAuditManagementDetail")
);
export const AppFormManagement = lazy(
  () => import("@/pages/AppFormManagement")
);
export const AppPollUpdate = lazy(() => import("@/pages/AppPollUpdate"));
export const AppCategoryPollList = lazy(
  () => import("@/pages/AppCategoryPollList")
);
export const AppSettings = lazy(() => import("@/pages/AppSettings"));
export const AppSettingsOthers = lazy(
  () => import("@/pages/AppSettingsOthers")
);
export const AppSettingsMap = lazy(() => import("@/pages/AppSettingsMap"));
export const AppPollDelete = lazy(() => import("@/pages/AppPollDelete"));
export const AppCategoryPollNew = lazy(
  () => import("@/pages/AppCategoryPollNew")
);
export const AppCategoryPollUpdate = lazy(
  () => import("@/pages/AppCategoryPollUpdate")
);
export const AppCategoryPollDelete = lazy(
  () => import("@/pages/AppCategoryPollDelete")
);
export const AppPollNew = lazy(() => import("@/pages/AppPollNew"));
export const AppPollImport = lazy(() => import("@/pages/AppPollImport"));
export const AppProjectManagement = lazy(
  () => import("@/pages/AppProjectManagement")
);
export const AppAuditManagementAnswerUpdate = lazy(
  () => import("@/pages/AppAuditManagementAnswerUpdate")
);
export const AppAuditManagementEntries = lazy(
  () => import("@/pages/AppAuditManagementEntries")
);
export const AppReportTemplateManagement = lazy(
  () =>
    import("@/pages/AppReportTemplateManagement/AppReportTemplateManagement")
);
export const AppReportNewFunctionality = lazy(
  () => import("@/pages/AppReportNewFunctionality")
);
export const AppBreadcrumbDashboardManagement = lazy(
  () => import("@/pages/AppBreadcrumbDashboardManagement")
);
export const AppInboxManagementList = lazy(
  () => import("@/pages/AppInboxManagementList")
);
export const AppLayoutDashboardManagement = lazy(
  () => import("@/pages/AppLayoutDashboardManagement")
);
export const AppLayoutDashboardUpdate = lazy(
  () => import("@/pages/AppLayoutDashboardUpdate")
);
export const AppLayoutDashboardNew = lazy(
  () => import("@/pages/AppLayoutDashboardNew")
);
export const AppLayoutDashboardDelete = lazy(
  () => import("@/pages/AppLayoutDashboardDelete")
);
export const AppLayoutDashboardWidget = lazy(
  () => import("@/pages/AppLayoutDashboardWidget")
);
export const AppLayoutDashboardWidgetNew = lazy(
  () => import("@/pages/AppLayoutDashboardWidgetNew")
);
export const AppLayoutDashboardWidgetUpdate = lazy(
  () => import("@/pages/AppLayoutDashboardWidgetUpdate")
);
export const AppLayoutDashboardWidgetDelete = lazy(
  () => import("@/pages/AppLayoutDashboardWidgetDelete")
);
export const AppBreadcrumbDashboardNew = lazy(
  () => import("@/pages/AppBreadcrumbDashboardNew")
);
export const AppBreadcrumbDashboardUpdate = lazy(
  () => import("@/pages/AppBreadcrumbDashboardUpdate")
);
export const AppBreadcrumbDashboardDelete = lazy(
  () => import("@/pages/AppBreadcrumbDashboardDelete")
);
export const AppUserManagement = lazy(
  () => import("@/pages/AppUserManagement")
);
export const AppUserManagementNew = lazy(
  () => import("@/pages/AppUserManagementNew")
);
export const AppUserManagementUpdate = lazy(
  () => import("@/pages/AppUserManagementUpdate")
);
export const AppUserManagementChangePassword = lazy(
  () => import("@/pages/AppUserManagementChangePassword")
);
export const AppUserManagementDelete = lazy(
  () => import("@/pages/AppUserManagementDelete")
);
export const AppUserManagementActivity = lazy(
  () => import("@/pages/AppUserManagementActivity")
);
export const AppUserProfile = lazy(() => import("@/pages/AppUserProfile"));
export const AppUserManagementProfiles = lazy(
  () => import("@/pages/AppUserManagementProfiles")
);
export const AppUserManagementProfilesNew = lazy(
  () => import("@/pages/AppUserManagementProfilesNew")
);
export const AppRolesManagementList = lazy(
  () => import("@/pages/AppRolesManagementList")
);
export const AppRolManagementCreate = lazy(
  () => import("@/pages/AppRolManagementCreate")
);
export const AppUserManagementProfilesUpdate = lazy(
  () => import("@/pages/AppUserManagementProfilesUpdate")
);
export const AppUserManagementProfilesDelete = lazy(
  () => import("@/pages/AppUserManagementProfilesDelete")
);
export const AppUserManagementCompany = lazy(
  () => import("@/pages/AppUserManagementCompany")
);
export const AppUserManagementCompanyNew = lazy(
  () => import("@/pages/AppUserManagementCompanyNew")
);
export const AppUserManagementCompanyUpdate = lazy(
  () => import("@/pages/AppUserManagementCompanyUpdate")
);
export const AppUserManagementCompanyDelete = lazy(
  () => import("@/pages/AppUserManagementCompanyDelete")
);
export const AppUserManagementBusiness = lazy(
  () => import("@/pages/AppUserManagementBusiness")
);
export const AppUserManagementBusinessNew = lazy(
  () => import("@/pages/AppUserManagementBusinessNew")
);
export const AppUserManagementBusinessUpdate = lazy(
  () => import("@/pages/AppUserManagementBusinessUpdate")
);
export const AppUserManagementBusinessDelete = lazy(
  () => import("@/pages/AppUserManagementBusinessDelete")
);
export const AppUserManagementSecurity = lazy(
  () => import("@/pages/AppUserManagementSecurity")
);
export const AppEquipmentManagement = lazy(
  () => import("@/pages/AppEquipmentManagement")
);
export const AppEquipmentManagementStockControl = lazy(
  () => import("@/pages/AppEquipmentManagementStockControl")
);
export const AppBatteriesManagement = lazy(
  () => import("@/pages/AppBatteriesManagement")
);
export const AppBatteriesManagementSummary = lazy(
  () => import("@/pages/AppBatteriesManagementSummary")
);
export const AppBatteriesManagementStockControl = lazy(
  () => import("@/pages/AppBatteriesManagementStockControl")
);
export const AppBatteriesManagementDetailBattery = lazy(
  () => import("@/pages/AppBatteriesManagementDetailBattery")
);
export const AppBatteriesManagementSiteDetail = lazy(
  () => import("@/pages/AppBatteriesManagementSiteDetails")
);
export const AppBatteriesManagementAddBattery = lazy(
  () => import("@/pages/AppBatteriesManagementAddBattery")
);
export const AppBatteriesManagementInstallBattery = lazy(
  () => import("@/pages/AppBatteriesManagementInstall")
);
export const AppBatteriesManagementAssignBattery = lazy(
  () => import("@/pages/AppBatteriesManagementAssign")
);
export const AppBatteriesManagementReturnBattery = lazy(
  () => import("@/pages/AppBatteriesManagementReturnBattery")
);
export const AppBatteriesManagementBatteryHistory = lazy(
  () => import("@/pages/AppBatteriesManagementBatteryHistory")
);
export const AppBatteriesManagementBatteryHistoryDetail = lazy(
  () => import("@/pages/AppBatteriesManagementBatteryHistoryDetail")
);

export const AppBatteriesManagementImport = lazy(
  () => import("@/pages/AppBatteriesManagementImport")
);

export const AppBatteriesManagementExport = lazy(
  () => import("@/pages/AppBatteriesManagementExport")
);

export const AppPageTest = lazy(() => import("@/pages/PageTest"));

export const AppDashboard = lazy(() => import("@/pages/AppDashboard"));

export const AppDriveTestControl = lazy(
  () => import("@/pages/AppDriveTestControl")
);

export const AppDriveTestControlDashboard = lazy(
  () => import("@/pages/AppDriveTestControlDashboard")
);

export const AppDriveTestControlSettings = lazy(
  () => import("@/pages/AppDriveTestControlSettings")
);
export const AppDriveTestControlNew = lazy(
  () => import("@/pages/AppDriveTestControlNew")
);
export const AppDriveTestControlUpdate = lazy(
  () => import("@/pages/AppDriveTestControlUpdate")
);
export const AppDriveTestControlCollectionCycleNew = lazy(
  () => import("@/pages/AppDriveTestControlCollectionCycleNew")
);
export const AppDriveTestControlCollectionCycleUpdate = lazy(
  () => import("@/pages/AppDriveTestControlCollectionCycleUpdate")
);
export const AppSiteView = lazy(() => import("@/pages/AppSiteView"));
export const AppAuditManagementAnswerNew = lazy(
  () => import("@/pages/AppAuditManagementAnswerNew")
);
export const AppBatteriesManagementDashboard = lazy(
  () => import("@/pages/AppBatteriesManagementDashboard")
);
export const AppAuditManagementDashboard = lazy(
  () => import("@/pages/AppAuditManagementDashboard")
);

export const AppBatteriesManagementDeleteBatteries = lazy(
  () => import("@/pages/AppBatteriesManagementDeleteBatteries")
);

export const AppSiteVisits = lazy(() => import("@/pages/AppSiteVisits"));
export const AppSiteVisitsDashboard = lazy(
  () => import("@/pages/AppSiteVisitsDashboard")
);
export const AppSiteVisitsWorkInProgress = lazy(
  () => import("@/pages/AppSiteVisitsWorkInProgress")
);
export const AppSiteVisitsDetails = lazy(
  () => import("@/pages/AppSiteVisitsDetails")
);

export const AppSiteVisitsLive = lazy(
  () => import("@/pages/AppSiteVisitsLive")
);
export const AppSiteVisitsLiveView = lazy(
  () => import("@/pages/AppSiteVisitsLiveView")
);
export const AppSiteVisitsGroupRealtimeNew = lazy(
  () => import("@/pages/AppSiteVisitsGroupRealTimeNew")
);
export const AppSiteVisitsGroupRealtimeDelete = lazy(
  () => import("@/pages/AppSiteVisitsGroupRealtimeDelete")
);
export const AppSiteVisitsGroupRealtimeUpdate = lazy(
  () => import("@/pages/AppSiteVisitsGroupRealtimeUpdate")
);
export const AppBatteriesManagementExportLoader = lazy(
  () => import("@/pages/AppBatteriesManagementExportLoader")
);
export const AppAuditManagementExternalData = lazy(
  () => import("@/pages/AppAuditManagementExternalData")
);
export const AppPollAnswerDelete = lazy(
  () => import("@/pages/AppPollAnswerDelete")
);
export const AppNetworkElement = lazy(
  () => import("@/pages/AppNetworkElement")
);
export const AppNetworkElementList = lazy(
  () => import("@/pages/AppNetworkElementList")
);
export const AppNetworkElementFormBegin = lazy(
  () => import("@/pages/AppNetworkElementFormBegin")
);
export const AppNetworkElementFormFinish = lazy(
  () => import("@/pages/AppNetworkElementFormFinish")
);
export const AppNetworkElementFormBasic = lazy(
  () => import("@/pages/AppNetworkElementFormBasicCards")
);
export const AppNetworkElementFormCustom = lazy(
  () => import("@/pages/AppNetworkElementFormCustomCards")
);
export const AppNetworkElementFormSystem = lazy(
  () => import("@/pages/AppNetworkElementFormSystemCards")
);
export const AppNetworkElementFormModules = lazy(
  () => import("@/pages/AppNetworkElementFormModules")
);
export const AppFormManagementAllForm = lazy(
  () => import("@/pages/AppFormManagementAllForms")
);
export const AppFormManagementCategories = lazy(
  () => import("@/pages/AppFormManagementCategories")
);

export const AppFormManagementTeams = lazy(
  () => import("@/pages/AppFormManagementTeams")
);

export const AppFormManagementAllFormsFavorites = lazy(
  () => import("@/pages/AppFormManagementAllFormsFavorites")
);
export const AppEntityDetail = lazy(() => import("@/pages/AppEntityDetail"));
export const AppNewPageBuilder = lazy(
  () => import("@/pages/AppNewPageBuilder")
);
export const AppNetworkFormBuilder = lazy(
  () => import("@/pages/AppNetworkFormBuilder")
);
export const AppNetworkForm = lazy(() => import("@/pages/AppEntityForm"));
export const AppNewNetworkElementList = lazy(
  () => import("@/pages/AppNewNetworkList")
);
export const AppNewModelsList = lazy(() => import("@/pages/AppNewModelsList"));
export const AppWorkOrder = lazy(() => import("@/pages/AppWorkOrder"));
export const AppLibraryDashboard = lazy(
  () => import("@/pages/AppLibraryDashboard")
);
export const AppAssetItemsList = lazy(
  () => import("@/pages/AppAssetsItemList")
);
export const AppTeamsManagement = lazy(
  () => import("@/pages/AppTeamsManagement")
);
export const AppPictureManagement = lazy(
  () => import("@/pages/AppPictureManagement")
);
export const AppAuditManagementForms = lazy(
  () => import("@/pages/AppAuditManagementForms")
);
