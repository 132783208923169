import AppLoader from "@/components/molecules/AppLoader";
import AppDialog from "@/components/sitehub-ui/atoms/AppDialog";
import { AppDialogStore } from "@/stores/app-dialog-store";
import { AppLoadingStore } from "@/stores/app-loading-store";
import { useObservable } from "@/utils/hook";
import { Dialog } from "@mui/material";
import { ReactNode } from "react";

export function AppProvider(props: { children?: ReactNode }) {
  const [isLoading] = useObservable(AppLoadingStore.getInstance().observable);
  const [dialogProps] = useObservable(AppDialogStore.getInstance().observable);

  return (
    <>
      {props.children}
      <Dialog open={!!isLoading}>
        <AppLoader />
      </Dialog>
      <AppDialog open={!!dialogProps?.shown} onClose={dialogProps?.onClose}>
        {dialogProps?.children}
      </AppDialog>
    </>
  );
}
