import { observableFromAbortableRequest } from "@/utils/map";
import { linkPaginationParser } from "@/utils/parser/link-parser";
import { buildQueryFromObject } from "@/utils/parser/query";
import { BehaviorSubject, from, interval, Observable, of, zip } from "rxjs";
import {
  bufferCount,
  catchError,
  map,
  mergeAll,
  switchMap,
  tap
} from "rxjs/operators";
import request from ".";

export function loadInBatch<T>(
  baseURL: string,
  query: Record<string, string | number | undefined> & { page: number },
  bufferSize = 3,
  msDelay = 500
): Observable<T[]> {
  return new Observable((observable) => {
    const subscription = observableFromAbortableRequest(
      request.getRequest(buildQueryFromObject(query, baseURL))<T[]>()
    )
      .pipe(
        map((data) => ({
          pagination: linkPaginationParser(data.headers.get("link")),
          data: data.data
        })),
        tap((paginatedData) => observable.next(paginatedData.data)),
        switchMap((paginatedData) => {
          if (query.page >= (paginatedData.pagination.last?.page || 0))
            return of([]);
          const restOfThePages = new Array(
            paginatedData.pagination.last?.page || 0
          )
            .fill(1)
            .map((_, index) =>
              buildQueryFromObject({ ...query, page: index + 1 }, baseURL)
            )
            .slice(1, undefined)
            .map((path) => () => request.getRequest(path));
          const $isLoadEnable = new BehaviorSubject(true);
          return zip(
            from(restOfThePages).pipe(bufferCount(bufferSize)),
            $isLoadEnable
          ).pipe(
            switchMap(([pageRequestCallBatch]) =>
              zip(from(pageRequestCallBatch), interval(msDelay)).pipe(
                map(([pageRequestCall]) =>
                  observableFromAbortableRequest(pageRequestCall()<T[]>())
                ),
                mergeAll()
              )
            ),
            tap((value) => observable.next(value.data)),
            bufferCount(bufferSize),
            tap(() => $isLoadEnable.next(true))
          );
        }),
        catchError((err) => of([]).pipe(tap(() => observable.error(err))))
      )
      .subscribe({
        complete: () => {
          !observable.closed && observable.complete();
        }
      });
    return () => {
      !subscription.closed && subscription.unsubscribe();
    };
  });
}
