import { observableFromAbortableRequest } from "@/utils/map";
import { AbortableRequest, RequestResponse } from "@/utils/request";
import { loadInBatch } from "@/utils/request/batch_load";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Observable, ObservableInput, from } from "rxjs";
import { scan, share } from "rxjs/operators";
export type ScreenDimensions = {
  width: number;
  height: number;
  /** @property xs Screen smaller than 600px */
  xs: boolean;
  /** @property sm Screen smaller than 960px */
  sm: boolean;
  /** @property md Screen smaller than 1280px */
  md: boolean;
  /** @property l Screen smaller than 1920px */
  l: boolean;
  /** @property xl Screen bigger than 1920px */
  xl: boolean;
};

const safeDocument: Document = document;

export function useScreenDimensions(): ScreenDimensions {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return {
    width,
    height,
    xs: width <= 600,
    sm: width <= 960,
    md: width <= 1280,
    l: width <= 1920,
    xl: width > 1920
  };
}

export function setPageTitle(window: Window) {
  return (title?: string) => (): string => {
    window.document.title = title ? `${title} | Sitehub` : "Sitehub";
    return window.document.title;
  };
}
export function useGeoLocation():
  | { lat: number; lng: number }
  | null
  | undefined {
  const [geoLocation, setGeoLocation] = useState<{
    lat: number;
    lng: number;
  }>();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (data) => {
        setGeoLocation({
          lat: data.coords.latitude,
          lng: data.coords.longitude
        });
      },
      (error) => {
        console.error(error);
        setGeoLocation({
          lat: 0,
          lng: 0
        });
      }
    );
  }, []);

  return geoLocation;
}
export function useObservable<Model>(
  $obs?: ObservableInput<Model>
): [Model | undefined] {
  const [value, setValue] = useState<Model>();
  const [$obsRef, set$ObsRef] = useState<Observable<Model>>();
  useEffect(() => {
    if ($obsRef || !$obs) return;
    set$ObsRef(from($obs));
  }, [$obs]);
  useEffect(() => {
    const subscription = $obsRef?.subscribe({
      next: setValue
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, [$obsRef]);
  return [value];
}
export function useService<
  Model,
  Query extends Record<string, string | number | undefined>
>(
  sourceFn: (query: Query) => AbortableRequest<Model>,
  initialQuery: Query,
  errorHandler: (error: any) => void = () => {}
): [
  RequestResponse<Model> | undefined,
  Dispatch<SetStateAction<Query>>,
  Observable<RequestResponse<Model>> | undefined
] {
  const [value, setValue] = useState<RequestResponse<Model>>();
  const [query, setQuery] = useState<Query>(initialQuery);
  const [observable, setObservable] =
    useState<Observable<RequestResponse<Model>>>();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    const localObservable = observableFromAbortableRequest(sourceFn(query));
    setObservable(localObservable);
  }, [query, firstLoad]);

  useEffect(() => {
    if (!observable) return;
    const subscription = observable.pipe(share()).subscribe({
      next: setValue,
      error: (error) => {
        console.log(error);
        setValue(undefined);
        errorHandler(error);
      }
    });
    return () => subscription.unsubscribe();
  }, [observable]);
  return [value, setQuery, observable];
}
export function useServiceBatch<Model, Query extends { page: number }>(
  baseURL: string,
  initialQuery: Query,
  errorHandler: (error: any) => void = () => {}
): [
  Model[] | undefined,
  Dispatch<SetStateAction<Query>>,
  Observable<Model[]> | undefined
] {
  const [value, setValue] = useState<Model[]>();
  const [query, setQuery] = useState<Query>(initialQuery);
  const [observable$, setObservable$] = useState<Observable<Model[]>>();

  useEffect(() => {
    const localObservable = loadInBatch<Model>(baseURL, query, 2, 1000).pipe(
      share(),
      scan<Model[], Model[]>((acc, value) => {
        return Array.isArray(value) ? [...acc, ...value] : acc;
      }, [])
    );
    setObservable$(localObservable);
  }, [query]);

  useEffect(() => {
    if (!observable$) return;
    console.log("subscription");
    const subscription = observable$.subscribe({
      next: setValue,
      error: (error) => {
        console.log(error);
        setValue([]);
        errorHandler(error);
      }
    });
    return () => {
      console.log("clean up");
      subscription.unsubscribe();
    };
  }, [observable$]);
  return [value, setQuery, observable$];
}
export const useInitialRender = () => {
  const ref = useRef(true);
  useEffect(() => {
    ref.current = false;
  }, []);
  return ref.current;
};
export const usePageBottom = () => {
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return reachedBottom;
};

export const useOutsideAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  triggerFunction: () => void
): void => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        triggerFunction();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, triggerFunction]);
};

export const useScrollBlock = () => {
  const scrollBlocked = useRef<boolean>();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    html.style.position = "relative";
    html.style.overflow = "hidden";
    body.style.position = "relative";
    body.style.overflow = "hidden";
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};
