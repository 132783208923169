import { classes } from "@/utils/css";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React, { forwardRef } from "react";
import styles from "./AppButton.module.css";

export type AppButtonProps = ButtonProps & {
  loading?: boolean;
  buttonType?:
    | "primary"
    | "secondary"
    | "info"
    | "neutral"
    | "danger"
    | "purple"
    | "link"
    | "orange";
};

function _AppButton(
  { buttonType, ...props }: AppButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <Button
        ref={ref}
        {...props}
        className={
          props.loading
            ? classes(
                styles.button,
                buttonType ? styles[buttonType] : "",
                styles.disabled,
                props.className || ""
              )
            : classes(
                styles.button,
                buttonType ? styles[buttonType] : "",
                props.className || ""
              )
        }
        onClick={(event) => {
          event.preventDefault();
          props.onClick && props.onClick(event);
        }}
      />
      {props.loading && (
        <CircularProgress size={20} className={styles.buttonProgress} />
      )}
    </div>
  );
}
const AppButton = forwardRef(_AppButton);
export default AppButton;
