import { PAGE_URL } from "@/data_source/constants";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppButton from "../../../components/atoms/AppButton";
import AppIconWifi from "../../../components/atoms/AppIconWifi";
import AppErrorTemplate from "../../../components/templates/AppErrorTemplate";
import { classes } from "../../../utils/css";
import styles from "./AppErrorPage.module.css";

export type AppNotFoundProps = {
  onGoBackClick?: () => void;
  onGoDashboardClick?: () => void;
};
function AppErrorPageView(props: AppNotFoundProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <AppErrorTemplate
      left={<AppIconWifi />}
      right={
        <Grid className={styles.messageContainer}>
          <h1 className={classes(styles.title, styles.notFound)}>
            {t("Sorry, something went wrong")}
          </h1>
          <Grid className={styles.buttonContainer}>
            <AppButton
              className={classes(styles.button, styles.info)}
              onClick={props.onGoBackClick}
            >
              {t("Go Back")}
            </AppButton>
            <AppButton
              href={PAGE_URL.HOME}
              className={classes(styles.button, styles.info, styles.link)}
              onClick={props.onGoDashboardClick}
            >
              {t("Go Dashboard")}
            </AppButton>
          </Grid>
        </Grid>
      }
    />
  );
}

export default AppErrorPageView;
