import { AbortableRequest, RequestResponse } from "@/utils/request";
import L from "leaflet";
import { Observable } from "rxjs";

export function changeProjectionSphericalMercator(
  center: L.LatLngLiteral
): L.LatLngLiteral {
  const position = L.Projection.SphericalMercator.unproject(
    L.point(center.lng, center.lat)
  );
  return position;
}
export function observableFromAbortableRequest<Model>(
  abortableRequest: AbortableRequest<Model>
): Observable<RequestResponse<Model>> {
  return new Observable((subscriber) => {
    abortableRequest.response
      .then((value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      })
      .catch((error) => {
        if (subscriber.closed) return;
        subscriber.error(error);
      });
    return abortableRequest.abort;
  });
}
export function toFileArray(files: FileList | null | undefined): File[] {
  if (!files || !files.length) return [];
  let filesArray: File[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    if (file) {
      filesArray = [...filesArray, file];
    }
  }
  return filesArray;
}
export function fromURLSearchParamsToObject<T extends Record<string, string>>(
  urlParams: URLSearchParams
): T {
  const query: Record<string, string> = {};
  urlParams.forEach((value, key) => (query[key] = value));
  return query as T;
}
