import { CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./AppLoader.module.css";

export type AppLoaderProps = {
  style?: React.CSSProperties;
};

function AppLoader(props: AppLoaderProps): JSX.Element {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={styles.loadingContainer}
      style={{ height: "100%", ...props.style }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        className={styles.loadingPaper}
      >
        <CircularProgress color="primary" className={styles.loading} />
        <Typography variant="body2" className={styles.loadingText}>
          Loading
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AppLoader;
