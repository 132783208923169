export class PAGE_URL {
  public static HOME = "/";
  public static SIGN_UP = "/sign-up";
  public static SIGN_UP_CODE_CONFIRMATION = "/sign-up/code-confirmation";
  public static SIGN_UP_CHECKBOX = "/sign-up/jobs-types";
  public static SIGN_UP_PROJECT = "/sign-up/project";
  public static AUDIT_MANAGEMENT_DETAILS = "/poll/answer/detail/:id";
  public static AUDIT_MANAGEMENT_LIST = "/poll/answer/list";
  public static AUDIT_MANAGEMENT_LIST_OLD = "/poll/answer/list/old";
  public static AUDIT_MANAGEMENT_EXPORT = "/poll/answer/export";
  public static AUDIT_MANAGEMENT_ENTRIES = "/poll/answer/entries";
  public static AUDIT_MANAGEMENT_UPDATE_ANSWER = "/poll/answer/:id/update";
  public static AUDIT_MANAGEMENT_NEW_ANSWER = "/poll/:id/answer/new";
  public static AUDIT_MANAGEMENT_DASHBOARD = "/poll/dashboard";
  public static AUDIT_MANAGEMENT_FORMS = "/poll/forms";
  public static AUDIT_MANAGEMENT_EXTERNAL_DATA = "/poll/external-data";
  public static FORMS_MANAGEMENT = "/poll-manager";
  public static FORMS_MANAGEMENT_ALL = "/poll-manager/list";
  public static FORMS_MANAGEMENT_CATEGORIES = "/poll-manager/categories";
  public static FORMS_MANAGEMENT_TEAMS = "/poll-manager/teams";
  public static FORMS_MANAGEMENT_ALL_FAVORITES = "/poll-manager/list/favorites";
  public static POLL_NEW = "/poll/new";
  public static POLL_IMPORT = "/poll/import";
  public static POLL_UPDATE = "/poll/:id/update";
  public static POLL_DELETE = "/poll/:id/delete";
  public static POLL_ANSWER_DELETE = "/poll/answer/:id/delete";
  public static AUDIT_MANAGEMENT = "/poll/";
  public static CATEGORY_POLL_LIST = "/categorypoll";
  public static CATEGORY_POLL_NEW = "/categorypoll/new";
  public static CATEGORY_POLL_UPDATE = "/categorypoll/:id/update";
  public static CATEGORY_POLL_DELETE = "/categorypoll/:id/delete";
  public static MAINTENANCE_AUDIT_FORM = "/maintenance/auditform/list";
  public static PROJECT_MANAGEMENT = "/project/list";
  public static TEAMS_MANAGEMENT = "/teams/list";
  public static REPORT_TEMPLATE_LIST = "/report/creator/list";
  public static REPORT_TEMPLATE_NEW = "/report/creator/new";
  public static REPORT_NEW_FUNCTIONALITY = "/report/new-functionality";
  public static SETTINGS = "/settings";
  public static MAP = "/settings/map";
  public static SETTINGS_OTHERS = "/settings/others";
  public static ERROR_404 = "/not-found";
  public static ERROR_PAGE = "/error-page";
  public static BREADCRUMB_DASHBOARD_MANAGEMENT = "/dashboardbreadcrumb/list";
  public static NEW_BREADCRUMB_DASHBOARD = "/dashboardbreadcrumb/new";
  public static UPDATE_BREADCRUMB_DASHBOARD = "/dashboardbreadcrumb/:id/update";
  public static DELETE_BREADCRUMB_DASHBOARD = "/dashboardbreadcrumb/:id/delete";
  public static INBOX_MANAGEMENT_LIST = "/inbox/list";
  public static LAYOUT_DASHBOARD_MANAGEMENT = "/dashboard/list";
  public static LAYOUT_DASHBOARD_UPDATE = "/dashboards/:id/update";
  public static LAYOUT_DASHBOARD_DELETE = "/dashboards/:id/delete";
  public static LAYOUT_DASHBOARD_NEW = "/dashboards/new";
  public static LAYOUT_DASHBOARD_WIDGETS = "/dashboards/:id/widget";
  public static LAYOUT_DASHBOARD_WIDGETS_NEW = "/dashboards/:id/widget/new";
  public static LAYOUT_DASHBOARD_WIDGETS_UPDATE =
    "/dashboards/:id/widget/:widgetId/update";
  public static LAYOUT_DASHBOARD_WIDGETS_DELETE =
    "/dashboards/:id/widget/:widgetId/delete";
  public static USER_MANAGEMENT = "/accounts/user/list";
  public static NEW_USER = "/accounts/user/create";
  public static UPDATE_USER = "/accounts/user/:id/update";
  public static CHANGE_PASSWORD = "/accounts/user/:id/change-password";
  public static USER_DELETE = "/accounts/user/:id/delete";
  public static USER_ACTIVITY = "/accounts/activities/list";
  public static MY_PROFILE = "/accounts/profile";
  public static LOGOUT = "/accounts/logout";
  public static USER_PROFILE = "/accounts/profiles/list";
  public static USER_PROFILE_DELETE = "/accounts/profiles/:id/delete";
  public static USER_PROFILE_UPDATE = "/accounts/profiles/:id/update";
  public static USER_PROFILE_NEW = "/accounts/profiles/new";
  public static USER_COMPANY = "/accounts/owncompany/list";
  public static USER_COMPANY_NEW = "/accounts/owncompany/new";
  public static USER_COMPANY_UPDATE = "/accounts/owncompany/:id/update";
  public static USER_COMPANY_DELETE = "/accounts/owncompany/:id/delete";
  public static USER_BUSINESS = "/accounts/business/list";
  public static USER_BUSINESS_NEW = "/accounts/business/new";
  public static USER_BUSINESS_UPDATE = "/accounts/business/:id/update";
  public static USER_BUSINESS_DELETE = "/accounts/business/:id/delete";
  public static USER_SECURITY = "/accounts/user-security";
  public static EQUIPMENTS_MANAGEMENT = "/equipments";
  public static EQUIPMENTS_MANAGEMENT_STOCK_CONTROL = "/equipments/list";
  public static BATTERIES_MANAGEMENT = "/batteryequipments";
  public static BATTERIES_MANAGEMENT_DASHBOARD = "/batteryequipments/dashboard";
  public static BATTERIES_MANAGEMENT_SUMMARY = "/batteryequipments/summary";
  public static BATTERIES_MANAGEMENT_STOCK_CONTROL = "/batteryequipments/list";
  public static BATTERIES_MANAGEMENT_DETAIL_BATTERY = "/batteries/detail/:id/";
  // public static BATTERIES_MANAGEMENT_ADD_BATTERY = '/batteryequipments/add-battery'
  public static BATTERIES_MANAGEMENT_DETAIL_SITE = "/batteries/site/:id/";
  public static BATTERIES_MANAGEMENT_DELETE_BATTERY =
    "/batteryequipments/delete";
  public static BATTERIES_MANAGEMENT_INSTALL_BATTERY =
    "/batteryequipments/install";
  public static BATTERIES_MANAGEMENT_ASSIGN_BATTERY =
    "/batteryequipments/assign";
  // public static  BATTERIES_MANAGEMENT_RETURN_BATTERY = '/batteryequipments/return-battery'
  public static BATTERIES_MANAGEMENT_BATTERY_HISTORY =
    "/batteryequipments/battery-history";
  public static BATTERIES_MANAGEMENT_BATTERY_HISTORY_DETAIL =
    "/batteries/battery-history/:id/";
  public static BATTERIES_MANAGEMENT_IMPORT_BATTERY =
    "/batteryequipments/import";
  public static BATTERIES_MANAGEMENT_EXPORT_BATTERY =
    "/batteryequipments/export";
  public static BATTERIES_MANAGEMENT_EXPORT_LOADER = "/batteries/export/loader";

  // public static BATTERIES_MANAGEMENT_ADD_BATTERY = '/batteryequipments/add-battery'
  // public static  BATTERIES_MANAGEMENT_RETURN_BATTERY = '/batteryequipments/return-battery'
  public static NEW_FIELD_MODEL_MANAGEMENT = "/fieldmodels/new/list/:model/";
  public static FIELD_MODEL_MANAGEMENT_EDIT = "/fieldmodels/edit/:id/";
  public static FIELD_MODEL_MANAGEMENT_CREATE = "/fieldmodels/create";
  public static FIELD_MODEL_MANAGEMENT_IMPORT = "/fieldmodels/import";
  public static MODEL_VIEWS = "/networks/model-view/:id";
  public static ENTITY_DETAIL = "/networks/entity-detail/:uuid/:local_id";
  public static DRIVE_TEST_CONTROL = "/drive-test-control/";
  public static DRIVE_TEST_CONTROL_DASHBOARD = "/drive-test-control/dashboard";
  public static DRIVE_TEST_CONTROL_CONFIG = "/drive-test-control/config";
  public static DRIVE_TEST_CONTROL_NEW = "/drive-test-control/new";
  public static DRIVE_TEST_CONTROL_UPDATE = "/drive-test-control/:id/update";
  public static DRIVE_TEST_CONTROL_COLLECTION_CYCLE_NEW =
    "/drive-test-control/:id/collection-cycle/new";
  public static DRIVE_TEST_CONTROL_COLLECTION_CYCLE_UPDATE =
    "/drive-test-control/:id/collection-cycle/:cycleId/update";
  public static SITE_VIEW = "/sites/:id";
  public static SITE_VISITS = "/sitevisit/";
  public static SITE_VISITS_DASHBOARD = "/sitevisit/dashboard";
  public static SITE_VISITS_LIST = "/sitevisit/list";
  public static SITE_VISITS_WORK_IN_PROGRESS = "/sitevisit/work-in-progress";
  public static SITE_VISITS_TRACKER_LIST = "/sitevisit/tracker/list";
  public static SITE_VISITS_REAL_TIME_LIST = "/sitevisit/real/time/list";
  public static SITE_VISITS_BROADCASTER = "/sitevisit/broadcaster/list";
  public static GROUP_REALTIME_NEW = "/group/realtime/new";
  public static GROUP_REALTIME_DELETE = "/group/realtime/:id/delete";
  public static GROUP_REALTIME_UPDATE = "/group/realtime/:id/update";
  public static REALTIME_LIST = "/real/time/list";
  public static NETWORK_ELEMENT = "/networks";
  public static NETWORK_ELEMENT_LIST = "/networks/list";
  public static NETWORK_ELEMENT_DETAILS = "/networks/:id";
  public static NETWORK_ELEMENT_VIEW = "/networks/:network/element/:id/";
  public static NETWORK_ELEMENT_FORM_BEGIN = "/networks/element/form/begin";
  public static NETWORK_ELEMENT_FORM_BASIC_CARDS =
    "/networks/element/form/basic-card/:id";
  public static NETWORK_ELEMENT_BASIC_CARDS_IMPORT =
    "/networks/element/form/basic-card/import";
  public static NETWORK_ELEMENT_FORM_FINISH = "/networks/element/form/finish";
  public static NETWORK_ELEMENT_CUSTOM =
    "/networks/:id/element/form/custom-cards/:card";
  public static NETWORK_ELEMENT_FORM_CUSTOM_CARDS =
    "/networks/element/form/custom-cards/:id";
  public static NETWORK_ELEMENT_FORM_SYSTEM_CARDS =
    "/networks/element/form/system-cards/:id";
  public static NETWORK_ELEMENT_FORM_MODULES =
    "/networks/element/form/modules/:id";
  public static NETWORK_ELEMENT_FORMBUILDER_MODELS_ADD =
    "/networks/:network/element/formbuilder/model/new";
  public static NETWORK_ELEMENT_PAGEBUILDER =
    "/networks/:network/element/pagebuilder/model/:id";
  public static NETWORK_ELEMENT_FORMBUILDER_MODELS_EDIT =
    "/network/:network/formbuilder/model/:model";
  public static WORK_ORDER = "/pendingissues/list";
  public static WORK_ORDER_SITE_CHECK = "/pendingissues/list";
  public static NETWORK_ELEMENT_PAGEBUILDER_NEW =
    "/networks/:network/pagebuilder/:schema";
  public static NETWORK_ELEMENT_PAGEBUILDER_EDIT =
    "/networks/:network/pagebuilder/:schema/:id";
  public static NETWORK_ELEMENT_FORMBUILDER_NEW =
    "/networks/:network/element/formbuilder/network";
  public static NETWORK_ELEMENT_FORMBUILDER_NEW_EDIT =
    "/networks/:network/element/formbuilder/network/:id";
  public static NETWORK_ELEMENT_FORM_NEW =
    "/networks/:network/element/form/schema/:id";
  public static NETWORK_ELEMENT_FORM_NEW_EDIT =
    "/networks/:network/element/form/schema/:id/entity/:entity";
  public static LIBRARY_DASHBOARD = "/library/dashboard";
  public static LIBRARY_FOLDER = "/library/dashboard/:id";
  public static PICTURE_MANAGEMENT = "/typepicture/list";
  public static SIGN_IN = "/sign-in";
  public static PASSWORD_RECOVERY = "/password-recovery";
}

export class APP_MODULES {
  public static SITE_VISITS = "Site Visits";
  public static AUDIT_MANAGEMENT = "AUDIT_MANAGEMENT";
  public static DRIVE_TEST = "DRIVE_TEST";
  public static INCIDENCE = "INCIDENCE";
  public static SITE_ACCEPTANCE = "SITE_ACCEPTANCE";
  public static INVENTORY = "INVENTORY";
  public static MODEL_VIEW = "MODEL_VIEW";
  public static BATTERIES = "BATTERIES";
  public static MAINTENANCE = "MAINTENANCE";
  public static WORK_ORDER = "WORK_ORDER";
  public static REPORTS = "REPORTS";
  public static NETWORK_ELEMENT = "NETWORK_ELEMENT";
  public static SETTINGS = "SETTINGS";
}

export class LOCAL_STORAGE_KEYS {
  public static VISITED_LOBBY = "VISITED_LOBBY";
}
