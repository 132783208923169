import { ErrorNotificationStore } from "@/stores/error-store";
import { useEffect } from "react";

type Props = {
  errorStatusCode: number;
};

function AppErrorDispatcher(props: Props): JSX.Element {
  useEffect(() => {
    ErrorNotificationStore.getInstance().notifyByStatus(
      props.errorStatusCode,
      ""
    );
  }, []);

  return <></>;
}

export default AppErrorDispatcher;
