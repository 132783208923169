import { ErrorNotificationStore } from "@/stores/error-store";
import { useService, useServiceBatch } from "@/utils/hook";
import { observableFromAbortableRequest } from "@/utils/map";
import { buildQueryFromObject } from "@/utils/parser/query";
import request, { AbortableRequest, RequestResponse } from "@/utils/request";
import { Dispatch, SetStateAction } from "react";
import { Observable } from "rxjs";
import { APIUserResponse } from "../models/APIUserResponse";
import { APIUserMeResponse } from "../models/APIUserMeResponse";

export function useUserMe(): [
  RequestResponse<APIUserResponse> | undefined,
  Dispatch<SetStateAction<Record<string, string | number | undefined>>>,
  Observable<RequestResponse<APIUserResponse>> | undefined
] {
  return useService<APIUserResponse, Record<string, any>>(
    () => request.getRequest("api/users/me")(),
    {},
    (error) => {
      if (error instanceof DOMException) return;
      ErrorNotificationStore.getInstance().notifyByStatus(
        error.status,
        error.message
      );
    }
  );
}
export function useUsers<
  Query extends Record<string, string | number | undefined>
>(
  initialQuery: Query
): [
  RequestResponse<APIUserResponse[]> | undefined,
  Dispatch<SetStateAction<Query>>,
  Observable<RequestResponse<APIUserResponse[]>> | undefined
] {
  return useService(
    (query) => request.getRequest(buildQueryFromObject(query, "api/users/?"))(),
    initialQuery,
    (error) => {
      if (error instanceof DOMException) return;
      ErrorNotificationStore.getInstance().notifyByStatus(
        error.status,
        error.message
      );
    }
  );
}
export function patchUsers(
  data: { groups: string[] },
  id: number
): AbortableRequest<APIUserResponse> {
  return request.patchRequest<{ groups: string[] }, APIUserResponse>(
    `api/users/${id}/`,
    data,
    {
      "content-type": "application/json"
    }
  )();
}
export function useAllUsersInBatch<
  T extends { page: number } & Record<string, string | number | undefined>
>(
  query: T
): [
  APIUserResponse[] | undefined,
  Dispatch<SetStateAction<T>>,
  Observable<APIUserResponse[]> | undefined
] {
  return useServiceBatch<APIUserResponse, T>("api/users/?", query);
}
export function updateUserPreference(
  name: string,
  value: string
): Observable<unknown> {
  return observableFromAbortableRequest(
    request.patchRequest(
      `api/userpreferences/${name}/`,
      {
        value: value
      },
      {
        "content-type": "application/json"
      }
    )()
  );
}

export function getUserMe(): Observable<RequestResponse<APIUserMeResponse>> {
  const token = localStorage.getItem("token");
  request.setGlobalHeader({
    Authorization: `Token ${token}`
  });
  return observableFromAbortableRequest(request.getRequest("api/users/me")());
}
