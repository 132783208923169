import { classes } from "@/utils/css";
import {
  Button,
  ButtonProps,
  CircularProgress,
  Typography
} from "@mui/material";
import React, { forwardRef } from "react";
import styles from "./AppButton.module.css";

export type AppButtonProps = Omit<ButtonProps, "variant" | "size" | "type"> & {
  loading?: boolean;
  type?: "filled" | "outlined";
  variant?:
    | "primary"
    | "info"
    | "success"
    | "danger"
    | "warning"
    | "dark"
    | "gray"
    | "light";
  size?: "sm" | "md" | "lg";
  label?: string;
  buttonType?: ButtonProps["type"];
};

function _AppButton(
  {
    type = "filled",
    variant = "primary",
    size = "lg",
    buttonType = "button",
    ...props
  }: AppButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <Button
        ref={ref}
        {...props}
        type={buttonType}
        className={
          props.loading
            ? classes(
                styles.button,
                styles[type],
                styles[variant],
                styles[size],
                styles.disabled,
                props.className || ""
              )
            : classes(
                styles.button,
                styles[type],
                styles[variant],
                styles[size],
                props.className || ""
              )
        }
        onClick={(event) => {
          event.preventDefault();
          props.onClick && props.onClick(event);
        }}
      >
        {props.label ? (
          <Typography variant="inherit" className={classes(styles.label)}>
            {props.label}
          </Typography>
        ) : null}
      </Button>
      {props.loading && (
        <CircularProgress size={20} className={styles.buttonProgress} />
      )}
    </div>
  );
}
const AppButton = forwardRef(_AppButton);
export default AppButton;
