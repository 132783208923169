import { PAGE_URL } from "@/data_source/constants";
import { signIn } from "@/data_source/services/APISignIn";
import { RouteAppComponentProps } from "@/router/routes";
import { AppLoadingStore } from "@/stores/app-loading-store";
import { setPageTitle } from "@/utils/hook";
import { withTransaction } from "@elastic/apm-rum-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { catchError, of, switchMap, tap } from "rxjs";
import { AppSignInView } from "./view";
import { getUserMe } from "@/data_source/services/UserService";
import { redirect } from "@/router/redirections";

function _AppSignIn(props: RouteAppComponentProps) {
  const [userData, setUserData] = useState<
    Partial<{
      email: string;
      password: string;
      remindMe: boolean;
    }>
  >({});
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const login = useCallback(() => {
    if (!userData.email || !userData.password) return;

    const subscription = of(true)
      .pipe(
        tap(() => AppLoadingStore.getInstance().add(true)),
        switchMap(() =>
          signIn({
            username: userData.email ?? "",
            password: userData.password ?? ""
          }).pipe(
            tap(() => {
              localStorage.setItem("formData", userData.email ?? "");
            }),
            catchError((err) => {
              console.error(err);
              setErrorMessage(
                "Error to login, your account or password was not correct"
              );
              AppLoadingStore.getInstance().add(false);
              return of(null);
            })
          )
        ),

        switchMap((signInResponse) => {
          if (!signInResponse?.data) return of(false);
          return getUserMe().pipe(
            tap((userData) => {
              if (!userData?.data?.is_verified) {
                setErrorMessage("Your account is not active");
                redirect(window.location)(
                  "https://wwwdev.sitehubpro.com/sign-up/code-confirmation" /// se debe cambiar por la url prod
                )("");
                tap(() => AppLoadingStore.getInstance().add(true));
                return;
              }
              if (!userData.data.projects?.length) {
                setErrorMessage(
                  "Your account does not have any projects configured"
                );
                tap(() => AppLoadingStore.getInstance().add(true));
                navigate(PAGE_URL.SIGN_UP_CHECKBOX);
                return;
              }
              tap(() => AppLoadingStore.getInstance().add(true)),
                setErrorMessage("");
              navigate(PAGE_URL.HOME);
            }),
            catchError((err) => {
              console.error("Error fetching user data:", err);
              setErrorMessage("Failed to fetch user details.");
              return of(false);
            })
          );
        }),

        tap(() => AppLoadingStore.getInstance().add(false))
      )
      .subscribe();

    return () => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    };
  }, [navigate, userData]);

  useEffect(() => {
    setPageTitle(window)(props.pageName)();
  }, []);

  return (
    <AppSignInView
      {...userData}
      errorMessage={errorMessage}
      onChange={(ev) => setUserData((prev) => ({ ...prev, ...ev }))}
      onLoginClick={login}
    />
  );
}

const AppSignIn = withTransaction("AppSignin", "component")(_AppSignIn);

export default AppSignIn;
