export function buildQueryFromArray(
  query: [string, string | number][],
  baseString = ""
): string {
  return query
    .filter((params) => Boolean(params[1]))
    .reduce(
      (acc, param, key) =>
        key === 0
          ? `${acc}${param[0]}=${param[1]}`
          : `${acc}&${param[0]}=${param[1]}`,
      baseString
    );
}

export function buildQueryFromObject(
  query: Record<string, string | number | undefined>,
  baseString = ""
): string {
  return baseString.concat(
    Object.keys(query)
      .filter((key) => {
        return !!query[key] && `${query[key]}`.length > 0;
      })
      .map((key) => `${key}=${query[key]}`)
      .reduce<string[]>((acc, current) => {
        return [...acc, current];
      }, [])
      .join("&")
  );
}
