import { classes } from "@/utils/css";
import { Grid } from "@mui/material";
import React from "react";
import styles from "./AppErrorTemplate.module.css";
export type AppNotFoundProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  rightFooter?: React.ReactNode;
};
function AppErrorTemplate(props: AppNotFoundProps): JSX.Element {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="row"
      className={classes(styles.container)}
    >
      <Grid className={styles.logoContainer}>{props.left}</Grid>
      <Grid className={styles.messageContainer}>{props.right}</Grid>
    </Grid>
  );
}

export default AppErrorTemplate;
