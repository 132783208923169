import { PAGE_URL } from "@/data_source/constants";
import { AppLoadingStore } from "@/stores/app-loading-store";
import { ErrorNotificationStore } from "@/stores/error-store";
import { useEffect } from "react";
import { delay, of, tap } from "rxjs";
import { useAppNavigator, useGoBack } from "../../router/redirections";
import { RouteAppComponentProps } from "../../router/routes";
import { setPageTitle } from "../../utils/hook";
import AppNotFoundPageView from "./view";

function AppNotFoundPage(props: RouteAppComponentProps): JSX.Element {
  const navigator = useAppNavigator();
  const goBack = useGoBack();
  useEffect(() => {
    setPageTitle(window)(props.pageName)();
  });
  useEffect(() => {
    AppLoadingStore.getInstance().add(false);
  }, []);

  return (
    <AppNotFoundPageView
      onGoBackClick={() => {
        of(true)
          .pipe(
            tap(() => goBack()),
            delay(200),
            tap(() =>
              ErrorNotificationStore.getInstance().notifyByStatus(200, "")
            )
          )
          .subscribe();
      }}
      onGoDashboardClick={() => navigator(PAGE_URL.HOME)}
    />
  );
}

export default AppNotFoundPage;
