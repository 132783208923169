import { ReactNode } from "react";
import { BehaviorSubject } from "rxjs";
import { Store } from "./store";

export type AppDialogEvent = {
  shown: boolean;
  onClose?: () => void;
  children?: ReactNode;
};

export class AppDialogStore extends Store<AppDialogEvent> {
  private static instance?: AppDialogStore;
  protected _observable: BehaviorSubject<AppDialogEvent | null>;
  private constructor() {
    super();
    this._observable = new BehaviorSubject<AppDialogEvent | null>({
      shown: false
    });
  }
  static getInstance(): AppDialogStore {
    AppDialogStore.instance = AppDialogStore.instance ?? new AppDialogStore();
    return AppDialogStore.instance;
  }
}
