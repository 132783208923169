type PageInfo = {
  perPage?: number;
  page?: number;
};
export type PaginationModel = {
  prev?: PageInfo;
  last?: PageInfo;
  next?: PageInfo;
  count?: number;
};
export function linkPaginationParser(
  linkHeader?: string | null
): PaginationModel {
  return (
    linkHeader
      ?.split(",")
      .map<PaginationModel>((link) => {
        const relMatch = link.match(/rel="(\w+)"/);
        const rel = relMatch
          ? (relMatch[1] as "next" | "prev" | "last")
          : "undefined";
        const perPageMatch = link.match(/(per_page|page_size)=(\d+)/);
        const perPage =
          perPageMatch && perPageMatch.length
            ? +perPageMatch[perPageMatch.length - 1]
            : 1;
        const pageMatch = link.match(/page=(\d+)/);
        const page =
          pageMatch && pageMatch.length ? +pageMatch[pageMatch.length - 1] : 1;
        const countMatch = link.match(/count=(\d+)/);
        const count =
          countMatch && countMatch.length
            ? +countMatch[countMatch.length - 1]
            : 0;

        return { [rel]: { perPage, page }, count };
      })
      .reduce<PaginationModel>(
        (prev, current) => ({
          prev: prev.prev || current["prev"],
          count: prev.count || current.count,
          last: prev.last || current["last"],
          next: prev.next || current["next"]
        }),
        {}
      ) || {}
  );
}
