import { APP_API_BASE_URL } from "@/data_source/constants/env";
import { ErrorNotificationStore } from "@/stores/error-store";
import { useNavigate } from "react-router-dom";

export function useAppNavigator() {
  const navigator = useNavigate();
  return (url: string, replace = false): void => {
    ErrorNotificationStore.getInstance().add(null);
    if (replace) {
      navigator(url, { replace: true });
    } else {
      navigator(url, { replace: false });
    }
  };
}
export function useGoBack(): () => void {
  const navigator = useNavigate();
  return () => {
    navigator(-1);
  };
}
export function redirect(location: Location) {
  return (baseUrl: string) =>
    (url: string): void => {
      location.href = `${baseUrl}/${url}`;
    };
}
export function redirectToBackendApp(location: Location) {
  return (url: string): void => {
    const formattedURL = url.match(RegExp("^/", "i")) ? url.slice(1) : url;
    location.href = `${APP_API_BASE_URL}/${formattedURL}`;
  };
}
export function openInNewTab(location: Location) {
  return (url: string, origin?: string): void => {
    window.open(`${origin ?? location.origin}${url}`);
  };
}
