import landingLogo from "@/assets/images/landing-logo.svg";
import logoImg from "@/assets/images/vector_logo3.svg";
import { classes } from "@/utils/css";
import { ReactNode } from "react";
import styles from "./AppLandingHorizontalTemplate.module.css";
type Props = {
  title?: string;
  message?: string;
  children?: ReactNode;
  footer?: ReactNode;
};
export function AppLandingTemplate(props: Props) {
  return (
    <div className={classes(styles.container)}>
      <div className={classes(styles.content)}>
        <div className={classes(styles.sections)}>
          <div className={classes(styles.left)}>
            <img className={classes(styles.logo)} src={landingLogo} />
            <h3 className={classes(styles.title)}>{props.title}</h3>
            <p className={classes(styles.message)}>{props.message}</p>
          </div>
          <div className={classes(styles.right)}>
            <img className={styles.logo} src={logoImg} />
            <div className={classes(styles.children)}>{props.children}</div>
            {!props.footer ? null : (
              <div className={classes(styles.footer)}>{props.footer}</div>
            )}
          </div>
        </div>
      </div>
      <div className={classes(styles.footer)}>
        <p>
          Copyrights © 2014 - 2025 & Todos los derechos reservados por
          Brightcomms
        </p>
      </div>
    </div>
  );
}
