import { BehaviorSubject } from "rxjs";

export abstract class Store<T> {
  protected abstract _observable: BehaviorSubject<T | null>;
  get observable(): BehaviorSubject<T | null> {
    return this._observable;
  }
  get value(): T | null {
    return this._observable.getValue();
  }

  add(value: T | null): void {
    this._observable.next(value);
  }
  addWithMapper(mapper: (value: T | null) => T | null): void {
    return this.add(mapper(this._observable.getValue()));
  }
}
