import { classes } from "@/utils/css";
import { Link, LinkProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import styles from "./AppLink.module.css";

export type AppLinkProps = LinkProps & {
  native?: boolean;
};

function AppLink(props: AppLinkProps): JSX.Element {
  return props.native ? (
    <Link
      {...{ ...props, native: undefined }}
      className={classes(styles.appLink, props.className || "")}
    />
  ) : (
    <RouterLink
      {...{ ...props, native: undefined, color: props.color?.toString() }}
      to={props.href || ""}
      className={classes(styles.appLink, props.className || "")}
    />
  );
}

export default AppLink;
