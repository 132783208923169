import { observableFromAbortableRequest } from "@/utils/map";
import request from "@/utils/request";
import { tap } from "rxjs";
import {
  APIResetPasswordRequest,
  APISignInRequest,
  APISignInResponse
} from "../models/APISignIn";

export function signIn(data: APISignInRequest) {
  return observableFromAbortableRequest(
    request.postRequest<APISignInRequest, APISignInResponse>(
      "api/login", // @ctomat - cambie la url de la api por la correspondiente
      data,
      {
        "content-type": "application/json"
      }
    )()
  ).pipe(
    tap((response) => {
      localStorage.setItem("token", response.data.token);
      document.cookie = `auth_token=${response.data.token}; Path=/; Domain=.sitehubpro.com; SameSite=Lax; Secure; Max-Age=${4 * 60 * 60}`;
      request.setGlobalHeader({
        Authorization: `Token ${response.data.token}`
      });
    })
  );
}
export function sendPasswordRecoveryEmail(email: string) {
  return observableFromAbortableRequest(
    request.postRequest(
      "api/accounts/send-reset-password-link/",
      {
        login: email
      },
      {
        "content-type": "application/json"
      }
    )()
  );
}
export function resetPassword(data: APIResetPasswordRequest) {
  return observableFromAbortableRequest(
    request.postRequest("api/accounts/reset-password/", data, {
      "content-type": "application/json"
    })()
  );
}
