import { AppLoadingStore } from "@/stores/app-loading-store";
import { useEffect } from "react";

function AppLoading(): JSX.Element {
  useEffect(() => {
    AppLoadingStore.getInstance().add(true);
    return () => {
      AppLoadingStore.getInstance().add(false);
    };
  }, []);
  return <></>;
}

export default AppLoading;
