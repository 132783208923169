import { classes } from "@/utils/css";
import styles from "./AppIconWifi.module.css";

function AppIconWifi(): JSX.Element {
  return (
    <div className={classes(styles.wifi, styles.weak, styles.loading)}>
      <div className={classes(styles.block)}></div>
    </div>
  );
}

export default AppIconWifi;
