import { Dialog } from "@mui/material";
import { styled } from "@mui/styles";

const AppDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    minHeight: "250px",
    borderRadius: "16px",
    display: "flex",
    maxWidth: "80vw"
  }
});

export default AppDialog;
