import { PAGE_URL } from "@/data_source/constants";
import { RouteAppComponentProps } from "@/router/routes";
import { AppLoadingStore } from "@/stores/app-loading-store";
import { ErrorNotificationStore } from "@/stores/error-store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { delay, of, tap } from "rxjs";
import { setPageTitle } from "../../utils/hook";
import AppErrorPageView from "./view";

function AppErrorPage(props: RouteAppComponentProps): JSX.Element {
  const navigator = useNavigate();
  useEffect(() => {
    setPageTitle(window)(props.pageName)();
  });
  useEffect(() => {
    AppLoadingStore.getInstance().add(false);
  }, []);
  return (
    <AppErrorPageView
      onGoBackClick={() => {
        of(true)
          .pipe(
            tap(() => navigator(-1)),
            delay(200),
            tap(() =>
              ErrorNotificationStore.getInstance().notifyByStatus(200, "")
            )
          )
          .subscribe();
      }}
      onGoDashboardClick={() => navigator(PAGE_URL.HOME)}
    />
  );
}

export default AppErrorPage;
