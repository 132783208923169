import iconCheckCircle from "@/assets/icons/icon-check-circle.svg";
import AppLink from "@/components/atoms/AppLink";
import AppButton from "@/components/sitehub-ui/atoms/AppButton";
import AppTextField from "@/components/sitehub-ui/atoms/AppTextField";
import { AppLandingTemplate } from "@/components/sitehub-ui/templates/AppLandingHorizontalTemplate";
import { PAGE_URL } from "@/data_source/constants";
import {
  resetPassword,
  sendPasswordRecoveryEmail
} from "@/data_source/services/APISignIn";
import { AppLoadingStore } from "@/stores/app-loading-store";
import { classes } from "@/utils/css";
import { Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { catchError, of, switchMap, tap } from "rxjs";
import styles from "./AppPasswordRecovery.module.css";

function PasswordRecoveryEmailInput(props: {
  onNextStepClicked?: () => void;
  onPrevStepClicked?: () => void;
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const sendEmail = useCallback(
    () =>
      of(true)
        .pipe(
          tap(() => AppLoadingStore.getInstance().add(true)),
          switchMap(() => sendPasswordRecoveryEmail(email)),
          tap(() => AppLoadingStore.getInstance().add(false)),
          tap(() => props.onNextStepClicked && props.onNextStepClicked()),
          catchError((err) => {
            console.log(err);
            return of(false);
          })
        )
        .subscribe(),
    [email, props.onNextStepClicked]
  );
  return (
    <Grid
      container
      direction="column"
      padding="42px 0px 50px 0px"
      flexWrap="nowrap"
      maxHeight="326px"
      height="326px"
    >
      <Typography
        className={classes(styles.label, styles.dark, styles.big)}
        marginBottom="4px"
      >
        {t("Forgot your password ?")}
      </Typography>
      <Typography className={classes(styles.label)} marginBottom="24px">
        {t(
          "Enter your email address and you will receive instructions on how to reset it."
        )}
      </Typography>
      <Grid container direction="column" rowGap={1} marginBottom="14px">
        <Grid item>
          <Typography className={styles.label}>{t("Email")}</Typography>
        </Grid>
        <Grid item>
          <AppTextField
            variant="outlined"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <AppButton
          disabled={!email}
          label={t("Send")}
          style={{ paddingLeft: "40px", paddingRight: "40px" }}
          onClick={sendEmail}
        />
      </Grid>
    </Grid>
  );
}
function PasswordRecoverySendSuccess(props: {
  onNextStepClicked?: () => void;
  onPrevStepClicked?: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      padding="46px 0px 72px 0px"
      alignItems="center"
      flexWrap="nowrap"
      maxHeight="326px"
      height="326px"
    >
      <img src={iconCheckCircle} alt="success" />
      <Typography
        className={classes(styles.label, styles.dark, styles.big)}
        margin="20px 0px 4px 0px"
      >
        {t("Link sent successfully")}
      </Typography>
      <Typography
        className={classes(styles.label)}
        textAlign="center"
        marginBottom="18px"
        maxWidth="397px"
      >
        {t("Check your email inbox and follow the instructions sent to you.")}
      </Typography>
      <AppLink
        className={classes(styles.link, styles.capitalize)}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          props.onPrevStepClicked && props.onPrevStepClicked();
        }}
      >
        {t("I have not received the email")}
      </AppLink>
    </Grid>
  );
}
function PasswordRecoveryNewPassword() {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const saveNewPassword = useCallback(() => {
    const user_id = params.get("user_id");
    const timestamp = params.get("timestamp");
    const signature = params.get("signature");
    return of(true)
      .pipe(
        tap(() => AppLoadingStore.getInstance().add(true)),
        switchMap(() =>
          resetPassword({
            password,
            signature: signature ?? "",
            timestamp: timestamp ?? "",
            user_id: user_id ?? ""
          })
        ),
        tap(() => AppLoadingStore.getInstance().add(false))
      )
      .subscribe({
        next: () => navigate(PAGE_URL.SIGN_IN),
        error: (err) => {
          console.log(err);
        }
      });
  }, [password]);
  return (
    <Grid
      container
      direction="column"
      padding="40px 0px 30px 0px"
      flexWrap="nowrap"
      maxHeight="326px"
      height="326px"
    >
      <Typography
        className={classes(styles.label, styles.dark, styles.big)}
        marginBottom="13px"
      >
        {t("Password restore")}
      </Typography>
      <Grid container direction="column" rowGap={1} marginBottom="14px">
        <Grid item>
          <Typography className={styles.label}>{t("New password")}</Typography>
        </Grid>
        <Grid item>
          <AppTextField
            variant="outlined"
            type="password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" rowGap={1} marginBottom="14px">
        <Grid item>
          <Typography className={styles.label}>
            {t("Repeat password")}
          </Typography>
        </Grid>
        <Grid item>
          <AppTextField
            variant="outlined"
            type="password"
            error={!confirmPassword || confirmPassword !== password}
            helperText={t("Confirm password must match password")}
            value={confirmPassword}
            onChange={(ev) => setConfirmPassword(ev.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <AppButton
          disabled={!password || confirmPassword !== password}
          label={t("Save")}
          style={{ paddingLeft: "32px", paddingRight: "32px" }}
          onClick={saveNewPassword}
        />
      </Grid>
    </Grid>
  );
}
const StepSwitcher = (props: {
  currentStep: PasswordRecoveryStep;
  onNextStepClicked?: () => void;
  onPrevStepClicked?: () => void;
}) => {
  switch (props.currentStep) {
    case PasswordRecoveryStep.sendSuccess:
      return (
        <PasswordRecoverySendSuccess
          onNextStepClicked={props.onNextStepClicked}
          onPrevStepClicked={props.onPrevStepClicked}
        />
      );
    case PasswordRecoveryStep.newPassword:
      return <PasswordRecoveryNewPassword />;
    default:
      return (
        <PasswordRecoveryEmailInput
          onNextStepClicked={props.onNextStepClicked}
          onPrevStepClicked={props.onPrevStepClicked}
        />
      );
  }
};
export enum PasswordRecoveryStep {
  emailInput,
  sendSuccess,
  newPassword
}
type Props = {
  currentStep: PasswordRecoveryStep;
  onNextStepClicked?: () => void;
  onPrevStepClicked?: () => void;
};

export function AppPasswordRecoveryView(props: Props) {
  const { t } = useTranslation();

  return (
    <AppLandingTemplate
      title={t("Password Recovery")}
      message={t("Don't worry, we'll help you regain your access.")}
      footer={
        <Grid
          container
          direction="column"
          alignItems="center"
          padding="42px 0px 0px 0px"
          gap={1}
        >
          <Typography
            className={classes(styles.label, styles.dark, styles.big)}
          >
            {t("Access your account")}
          </Typography>
          <Typography className={classes(styles.label)}>
            {t("If you have the access data to your account")}
            <AppLink href={PAGE_URL.SIGN_IN} className={classes(styles.link)}>
              {t("Enter here")}
            </AppLink>
          </Typography>
        </Grid>
      }
    >
      <StepSwitcher
        currentStep={props.currentStep}
        onNextStepClicked={props.onNextStepClicked}
        onPrevStepClicked={props.onPrevStepClicked}
      />
    </AppLandingTemplate>
  );
}
