import AppLink from "@/components/atoms/AppLink";
import AppButton from "@/components/sitehub-ui/atoms/AppButton";
import AppTextField from "@/components/sitehub-ui/atoms/AppTextField";
import { AppLandingTemplate } from "@/components/sitehub-ui/templates/AppLandingHorizontalTemplate";
import { PAGE_URL } from "@/data_source/constants";
import { classes } from "@/utils/css";
import { Checkbox } from "@mantine/core";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./AppSignIn.module.css";

type Props = {
  email?: string;
  password?: string;
  remindMe?: boolean;
  errorMessage?: string;
  onLoginClick?: () => void;
  onChange?: (
    ev: Partial<{ email: string; password: string; remindMe: boolean }>
  ) => void;
};
export function AppSignInView(props: Props) {
  const { t } = useTranslation();
  return (
    <AppLandingTemplate
      title={t("Sign-in with your account")}
      message={t("Welcome back")}
      footer={
        <Grid
          container
          direction="column"
          alignItems="center"
          padding="42px 0px 0px 0px"
          gap={1}
        >
          <Typography
            className={classes(styles.label, styles.dark, styles.big)}
          >
            {t("Forgot your password ?")}
          </Typography>
          <Typography className={classes(styles.label)}>
            {t("Don't worry,")}
            <AppLink
              href={PAGE_URL.PASSWORD_RECOVERY}
              className={classes(styles.link)}
            >
              {t("Click here")}
            </AppLink>
            {t("to restore it")}
          </Typography>
        </Grid>
      }
    >
      <Grid container direction="column" gap={2} padding="42px 0px 50px 0px">
        <Grid container direction="column" rowGap={1}>
          <Grid item>
            <Typography className={styles.label} variant="inherit">
              {t("Email")}
            </Typography>
          </Grid>
          <Grid item>
            <AppTextField
              type="email"
              variant="outlined"
              value={props.email ?? ""}
              error={!props.email}
              helperText={t("Enter email")}
              onChange={(ev) =>
                props.onChange && props.onChange({ email: ev.target.value })
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="column" rowGap={1}>
          <Grid item>
            <Typography className={styles.label}>{t("Password")}</Typography>
          </Grid>
          <Grid item>
            <AppTextField
              type="password"
              variant="outlined"
              value={props.password ?? ""}
              error={!props.password}
              helperText={t("Enter password")}
              onChange={(ev) =>
                props.onChange && props.onChange({ password: ev.target.value })
              }
            />
          </Grid>
        </Grid>
        {props.errorMessage && (
          <Grid item>
            <Typography className={classes(styles.label, styles.red)}>
              {t(props.errorMessage)}
            </Typography>
          </Grid>
        )}
        <Grid container direction="row" justifyContent="space-between">
          <Checkbox
            label={
              <Typography className={classes(styles.label, styles.dark)}>
                {t("Remind me")}
              </Typography>
            }
            checked={props.remindMe ?? false}
            onChange={(ev) =>
              props.onChange && props.onChange({ remindMe: ev.target.checked })
            }
          />
          <AppButton
            label={t("Login")}
            style={{ paddingLeft: "40px", paddingRight: "40px" }}
            onClick={() => props.onLoginClick && props.onLoginClick()}
          />
        </Grid>
      </Grid>
    </AppLandingTemplate>
  );
}
