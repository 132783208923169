import { APP_API_BASE_URL } from "@/data_source/constants/env";
import { RequestError } from "./RequestError";

export type RequestResponse<T> = {
  headers: Headers;
  data: T;
};

export type AbortableRequest<T> = {
  response: Promise<RequestResponse<T>>;
  abort: () => void;
};

export function request(method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE") {
  return (headers: Record<string, string> = {}) =>
    (
      cache:
        | "default"
        | "no-cache"
        | "reload"
        | "force-cache"
        | "only-if-cached"
    ) =>
    (url: string) =>
    (body?: string | FormData) =>
    <T>(): AbortableRequest<T> => {
      const controller = new AbortController();
      return {
        response: fetch(url, {
          method,
          headers,
          cache,
          body,
          mode: "cors",
          signal: controller.signal
        })
          .then(async (response) => {
            if (response.ok) {
              try {
                return {
                  headers: response.headers,
                  data: await response.json()
                };
              } catch {
                return { headers: response.headers, data: response.body };
              }
            }
            try {
              throw new RequestError(response.status, await response.json());
            } catch (error: any) {
              throw new RequestError(response.status, error.message);
            }
          })
          .catch((error) => {
            throw new RequestError(
              error.status || 200,
              `Connection Error: ${error.message}`
            );
          }),
        abort: () => controller.abort()
      };
    };
}

export function getRequest(
  url: string,
  headers?: Record<string, string>
): <T>() => AbortableRequest<T> {
  return request("GET")(headers)("default")(url)();
}
export function postRequest(url: string) {
  return <T>(
    data: T,
    headers?: Record<string, string>
  ): (<U>() => AbortableRequest<U>) =>
    request("POST")(headers)("no-cache")(url)(JSON.stringify(data));
}

export function putRequest(url: string, headers?: Record<string, string>) {
  return (data: Record<string, any>): (<T>() => AbortableRequest<T>) =>
    request("PUT")(headers)("no-cache")(url)(JSON.stringify(data));
}
export function deleteRequest(
  url: string,
  headers?: Record<string, string>
): <T>() => AbortableRequest<T> {
  return request("DELETE")(headers)("default")(url)();
}

export type RequestInstance = {
  getRequest: (
    url: string,
    headers?: Record<string, string>
  ) => <T>() => AbortableRequest<T>;
  postRequest: <T, R>(
    url: string,
    data: T,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  postFormDataRequest: <R>(
    url: string,
    data: FormData,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  putRequest: <T, R>(
    url: string,
    data: T,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  putFormDataRequest: <R>(
    url: string,
    data: FormData,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  patchRequest: <T, R>(
    url: string,
    data: T,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  patchFormDataRequest: <R>(
    url: string,
    data: FormData,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  deleteRequest: <R>(
    url: string,
    headers?: Record<string, string>
  ) => () => AbortableRequest<R>;
  fileRequest: (
    url: string,
    headers?: Record<string, string>
  ) => () => AbortableRequest<string>;
  setGlobalHeader: (headers: Record<string, string>) => RequestInstance;
};

type RequestConfig = {
  baseUrl: string;
  headers?: Record<string, string>;
};

export function createRequestInstance(
  initialConfig: RequestConfig
): RequestInstance {
  let config = { ...initialConfig };
  return {
    getRequest: (url: string, headers?: Record<string, string>) => {
      return request("GET")({ ...config.headers, ...headers })("default")(
        url.match("^(http|file).+") ? url : `${config.baseUrl}/${url}`
      )();
    },
    postRequest: <T>(
      url: string,
      data: T,
      headers?: Record<string, string>
    ) => {
      return request("POST")({ ...config.headers, ...headers })("no-cache")(
        `${config.baseUrl}/${url}`
      )(JSON.stringify(data));
    },
    postFormDataRequest: (
      url: string,
      data: FormData,
      headers?: Record<string, string>
    ) => {
      return request("POST")({
        ...config.headers,
        ...headers
      })("no-cache")(`${config.baseUrl}/${url}`)(data);
    },
    putFormDataRequest: (
      url: string,
      data: FormData,
      headers?: Record<string, string>
    ) => {
      return request("PUT")({
        ...config.headers,
        ...headers
      })("no-cache")(`${config.baseUrl}/${url}`)(data);
    },
    putRequest: <T>(url: string, data: T, headers?: Record<string, string>) => {
      return request("PUT")({ ...config.headers, ...headers })("no-cache")(
        `${config.baseUrl}/${url}`
      )(JSON.stringify(data));
    },
    patchRequest: <T>(
      url: string,
      data: T,
      headers?: Record<string, string>
    ) => {
      return request("PATCH")({ ...config.headers, ...headers })("no-cache")(
        `${config.baseUrl}/${url}`
      )(JSON.stringify(data));
    },
    patchFormDataRequest: (
      url: string,
      data: FormData,
      headers?: Record<string, string>
    ) => {
      return request("PATCH")({ ...config.headers, ...headers })("no-cache")(
        `${config.baseUrl}/${url}`
      )(data);
    },
    deleteRequest: (url: string, headers?: Record<string, string>) => {
      return request("DELETE")({ ...config.headers, ...headers })("default")(
        `${config.baseUrl}/${url}`
      )();
    },
    fileRequest: (url: string, headers?: Record<string, string>) => {
      const controller = new AbortController();
      return () => ({
        response: fetch(`${config.baseUrl}/${url}`, {
          method: "GET",
          signal: controller.signal,
          headers: { ...config.headers, ...headers }
        })
          .then(async (response) => ({
            headers: response.headers,
            data: await response.blob()
          }))
          .then((responseBlob) => ({
            ...responseBlob,
            data: URL.createObjectURL(responseBlob.data)
          }))
          .catch((error) => {
            throw new RequestError(
              error.status || 200,
              `Connection Error: ${error.message}`
            );
          }),
        abort: controller.abort
      });
    },
    setGlobalHeader: (headers: Record<string, string>) => {
      config = { ...initialConfig, headers };
      return createRequestInstance(config);
    }
  };
}

export function getRequestUrl(string: string): string | undefined {
  const match = string.match(/(api\/(\w+\/)+)/);
  return match ? match[0] : undefined;
}

export default createRequestInstance({
  baseUrl: APP_API_BASE_URL
});
